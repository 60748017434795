body {
  margin: 0 !important;
  font-family: 'GT Eesti Pro Display', Arial, sans-serif !important;
}

input[autocomplete="one-time-code"] {
  padding-left: 1px;
  padding-right: 1px;
}




/* Hover Effects */

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: white;
}

.hover-underline-animation::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: bottom center;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom center;
}



.swal-container {
    z-index: 99999;
}




/* ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background-color: white;
}
::-webkit-scrollbar-thumb {
  background-color: #526D82;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #27374D;
} */